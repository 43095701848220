<template>
  <div class="clinic-dashboard-container">
    <vs-popup title="" :active.sync="showAssignModal">
      <h4>Assign An Owner</h4>

      <vs-row vs-justify="center" class="available-followers-list">
        <vs-col class="vx-col w-full md:w-1/2 sm:w-1/2 mb-base" vs-w="6" v-bind:key="item.id"
                v-for="item in availableFollowers">
          <div @click="assignFollower(item)">
            <vs-card class="available-followers-item text-center cursor-pointer">
              <div class="available-followers-item-content">
                <div class="follower-name">{{ item.name }}</div>
                <div class="follower-role">{{ item.role !== null ? item.role.toUpperCase() : '' }}</div>
              </div>
            </vs-card>
          </div>

        </vs-col>
      </vs-row>
    </vs-popup>
    <section class="registration-tracking">
      <h1 class="registration-tracking-title">Cost of Care</h1>
      <p class="requests_count">You have
        {{ parseInt(phoneRegistrationTotalLeads) + parseInt(admissions.length) }}
        pending Pre-Op items
      </p>

      <p class="requests_count">You have
        {{ parseInt(phoneRegistrationTotalLeads) + parseInt(admissions.length) }}
        pending Post-Op items
      </p>


    </section>

    <section class="request-statistics">
    <p class="followup-rules-description">These checklits are to help to improve communication and teamwork by bringing together the careteam involved in care to confirm that critical care and safety measures are performed before, during and after an operation.</p>
  </section>
    
    <section class="request-st  atistics">
      <div class="statistics-card">
        <statistics-card-line icon="UsersIcon" :statistic="phoneRegistrationTotalLeads"
                              statisticTitle="Personel"
                              :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>
      <div class="statistics-card">
        <statistics-card-line icon="UsersIcon" :statistic="admissions.length"
                              statisticTitle="Equipment"
                              :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>

      <div class="statistics-card">
        <statistics-card-line icon="UsersIcon" :statistic="admissions.length"
                              statisticTitle="Consumable"
                              :chartData="subscribersGained.series" type="area"></statistics-card-line>
      </div>
    </section>


    <section class="registration-list">
      <div class="registration-list-wrapper">
        <div class="registration-list-header">
          <div class="registration-title">
            Health Screening
          </div>
          <div class="registration-subtitle">
            total cost calculation for a patient receiving a health screening
          </div>
        </div>
        <div class="registration-list-content">
          <vs-table v-model="selected" pagination :data="phoneRegistrationLeads">
            <template slot="thead">
              <vs-th></vs-th>
              <!--              <vs-th>ASSIGNMENT</vs-th>-->
              <vs-th>Care Segment</vs-th>
              <vs-th>Personel type</vs-th>
              <vs-th>Time(min)</vs-th>
              <vs-th>Probability 1: % of time step takes place</vs-th>
              <vs-th>Probability 2: % of time personnel is used</vs-th>      
              <vs-th>Space</vs-th>
              <vs-th>Type of equipment</vs-th>
              <vs-th>Total (EUR)</vs-th>




            </template>
            <template>
              <!--vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  <vs-button v-if="tr.owner" disabled color="grey"><b>Assign</b></vs-button>
                  <vs-button v-else @click="showAssignFollowerModal(data[indextr].id)" color="primary" type="filled"><b>Assign</b>
                  </vs-button>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ $moment(data[indextr].date).format('DD-MM-YYYY HH:mm:ss') }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ $moment(data[indextr].date).fromNow() }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ data[indextr].from }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ data[indextr].to }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ $moment(data[indextr].date).format('DD-MM-YYYY HH:mm:ss') }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ $moment(data[indextr].date).fromNow() }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ data[indextr].from }}</span>
                </vs-td>
                <vs-td :data="data[indextr].id">
                  <span>{{ data[indextr].to }}</span>
                </vs-td>
              </!--vs-tr -->
              <vs-tr>
                <vs-td>
                 
                  <vs-button @click="showAssignFollowerModal(data[indextr].id)" color="primary" type="filled"><b>Assign</b>
                  </vs-button>
                </vs-td>
                <vs-td >
                  <span>Preop</span>
                </vs-td>
                <vs-td >
                  <span>Patient Check In</span>
                </vs-td>
                <vs-td >
                  <span>Registrar</span>
                </vs-td>
                <vs-td >
                  <span>2</span>
                </vs-td>
                <vs-td >
                  <span>100</span>
                </vs-td>
                <vs-td >
                  <span>100</span>
                </vs-td>
                <vs-td >
                  <span>Screening Check In</span>
                </vs-td>
                <vs-td >
                  <span>0.45</span>
                </vs-td>
                <vs-td >
                  <span></span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </section>

    <section class="registration-list">
      <div class="registration-list-wrapper">
        <div class="registration-list-header">
          <div class="registration-title">
            Total cost of receiving health screening :  {{ parseInt(phoneRegistrationTotalLeads) + parseInt(admissions.length) }}
          </div>
          

         
        </div>
        
      </div>
    </section>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from './ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'
import vSelect from 'vue-select'
import Prism from 'vue-prism-component'
import modulePatientFollower from '@/store/patientFollower/modulePatientFollower.js'

export default {
  data() {
    return {
      showAssignModal: false,
      userId: null,
      checkpointReward: {},
      subscribersGained: {},
      ordersRecevied: {},
      salesBarSession: {},
      supportTracker: {},
      productsOrder: {},
      salesRadar: {},
      phoneRegistrationTotalLeads: "0",
      equipmentUsage: "0",
      phoneRegistrationLeads: [],
      activities: [],
      selected: [],
      analyticsData,
      dispatchedOrders: []
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline,
    vSelect,
    Prism
  },
  methods: {
    showAssignFollowerModal(id) {
      this.userId = id;
      this.showAssignModal = true;
    },
    assignFollower(item) {
      let payloadObj = {};
      payloadObj.payload = { owner: item.name };
      payloadObj.params = {
        attributesToBeUpdated: 'owner',
        id: this.userId
      };
      this.$store.dispatch("modulePatientFollower/assignFollower", payloadObj)
        .then(() => {
          this.showAssignModal = false;
          this.$vs.notify({
            title: 'Assign Follower',
            text: 'Follower assigned successfully!',
            color: 'success',
          })
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  computed: {
    availableFollowers() {
      return this.$store.state.patientFollower.availableFollowers;
    },
    admissions() {
      return this.$store.state.patientFollower.admissions;
    }
  },
  created() {
    this.$http.get('/api/user/checkpoint-reward')
      .then((response) => {
        this.checkpointReward = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Subscribers gained - Statistics
    this.$http.get('/api/card/card-statistics/subscribers')
      .then((response) => {
        this.subscribersGained = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Orders - Statistics
    this.$http.get('/api/card/card-statistics/orders')
      .then((response) => {
        this.ordersRecevied = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Sales bar - Analytics
    this.$http.get('/api/card/card-analytics/sales/bar')
      .then((response) => {
        this.salesBarSession = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Support Tracker
    this.$http.get('/api/card/card-analytics/support-tracker')
      .then((response) => {
        this.supportTracker = response.data
      })
      .catch((error) => {
        console.log(error)
      })
    //Sign Up
    this.$http.get('https://sheets.googleapis.com/v4/spreadsheets/1qyClKXVwYHIlJl_UshTHrC06moFYyw_5UXz05Hc2p2Q/values/Sheet1!A1:N100?key=AIzaSyDUI40h3hMpPZcVPYEzins8en4PxcG9KSM')
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
    // Products Order
    this.$http.get('/api/card/card-analytics/products-orders')
      .then((response) => {
        this.productsOrder = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Sales Radar
    this.$http.get('/api/card/card-analytics/sales/radar')
      .then((response) => {
        this.salesRadar = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    // Dispatched Orders
    this.$http.get('/api/table/dispatched-orders')
      .then((response) => {
        this.dispatchedOrders = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    this.$http.get('/activity/getAll')
      .then((response) => {
        console.log(response.data.activitys);
        this.activities = response.data.activitys;

      })
      .catch((error) => {
        console.log(error)
      })

    if (!modulePatientFollower.isRegistered) {
      this.$store.registerModule('modulePatientFollower', modulePatientFollower)
      modulePatientFollower.isRegistered = true
    }
    this.$store.dispatch("modulePatientFollower/getAdmissions");
    this.$store.dispatch("modulePatientFollower/getAvailableFollowers");
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.clinic-dashboard-container {
  max-width: 1400px;
  margin: 0 auto;

  .registration-tracking {
    margin: 0 35px;
    padding: 65px 0;
    background: linear-gradient(120deg, rgba(#01B1A2, 1), rgba(#037F82, 1));
    border-radius: 10px;

    .registration-tracking-title {
      color: $white;
      font-weight: 700;
      text-align: center;
      margin-bottom: 5px;
    }

    .requests_count {
      color: $white;
      font-size: 24px;
      text-align: center;
    }
  }

  .request-statistics {
    margin: 50px 35px 75px 35px;
    display: flex;

    .statistics-card {
      min-height: 300px;
      box-shadow: 2px 2px 8px #ddd;
      border-radius: 15px;

      .pb-0 {
        padding-bottom: 40px !important;
      }

      .feather-icon {
        visibility: hidden !important;
        padding: 0 !important;
      }

      &:last-child {
        margin-left: 45px;
      }

      .truncate {
        padding-left: 15px;

        h2 {
          color: $darkGreen;
          font-size: 38px;
          margin-bottom: 10px !important;
        }

        span {
          color: $lightGreen;
        }
      }
    }
  }

  .followup-rules {
    margin: 0 35px;
    color: $lightGreen;

    .followup-rules-description {
      padding-bottom: 15px;
    }

    .followup-rule-item {
      padding-bottom: 7px;
      display: flex;

      .followup-rule-icon {
        padding-right: 7px;
        padding-top: 2px;
      }
    }
  }

  .registration-list {
    margin: 50px 35px;

    .registration-list-wrapper {
      padding: 30px 30px 40px 60px;
      border-radius: 15px;
      box-shadow: 2px 2px 8px #ddd;

      .vs-table--pagination {
        margin: 20px 0;
        padding-right: 60px;
      }

      .registration-list-content {
        box-shadow: 4px 4px 8px #ddd;

        .vs-con-table .vs-con-tbody {
          border: none;
        }

        .vs-table--thead {
          color: $lightGreen;
          font-weight: 700;

          th {
            font-size: 16px;
          }
        }

        .vs-table--td {
          color: $textColor;
        }
      }

      .registration-list-header {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        .registration-title {
          font-size: 24px;
          font-weight: 700;
          color: $green;
        }

        .registration-subtitle {
          font-size: 18px;
          color: $lightGreen;
          padding-left: 10px;
          font-style: italic;
        }
      }
    }
  }
}
.vs-popup {
  .vs-popup--header {
    background: $white;
    height: 20px;
  }
  .vs-popup--close {
    border-radius: 50%;
    color: $darkGreen;
    font-weight: 700;
    box-shadow: 0 1px 1px 0 #ddd;
    transform: translate(10px,-8px);
  }
  .vs-popup--content {
    padding: 0 30px 15px;
    h4 {
      color: $darkGreen;
      font-weight: 700;
      margin-bottom: 25px;
    }
  }
  .vs-popup--content {
    margin: 0;
    width: 100%;
  }
}
.available-followers-list {
  .available-followers-item {
    background-color: $lightYellow;
    width: auto;
    margin-bottom: 0;
    .available-followers-item-content {
      padding: 30px 0;
      color: $darkGreen;
      .follower-role {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
      }
      .follower-name {
        font-size: 12px;
      }
    }
  }
  .vs-col {
    margin-bottom: 0 !important;
    &:nth-child(odd) {
      padding-right: 8px !important;
    }
    &:nth-child(even) {
      padding-left: 8px !important;
    }
    .vs-button:disabled {
      font-size: 16px;
    }
  }
}
@media (max-width: 576px) {
  .request-statistics {
    flex-direction: column;
    .statistics-card {
      &:last-child {
        margin-left: 0 !important;
        margin-top: 40px;
      }
    }
  }
}

</style>
